









import {Component, Vue} from "vue-property-decorator";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import {MuvekkilListResponseProvider} from "@/services/MuvekkilService";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
@Component({
    components: {MuvekkilList}
})
export default class MuvekkilListe extends Vue {
    items: Array<MuvekkilEntity> | null = null;

    mounted() {
        window.setTimeout(() => {
            let index: number = parseInt(this.$route.params.id) - 1;
            this.items = MuvekkilListResponseProvider(5, index).data;
            console.log("Muvekkiller:", this.items);
        }, 200);
    }
}
